import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Formmain from '../components/Prebuilt/Formmain'
import Formstart from '../components/Prebuilt/Formstart'
import PageWrapper from '../components/Structure/PageWrapper'

class Page extends Component {
	render() {
		const {
			data: {
				craft: { entry }
			}
		} = this.props

		return (
			<PageWrapper
				location={this.props.location}
				entry={entry}
				imageObject={
					!entry.featuredImage || !entry.featuredImage.length
						? null
						: entry.featuredImage[0]
				}
				title={entry.hideTitle ? null : entry.title}
				matrixBuilder={entry.matrixBuilder}
				bonusContent={[
					entry.slug === 'apply-to-join-pancakes' ? <Formmain /> : null,
					entry.slug === 'start-a-project' ? <Formstart /> : null
				]}
			/>
		)
	}
}

export default Page

export const pageQuery = graphql`
	query QueryPage($id: [Int]!) {
		craft {
			entry(id: $id) {
				... on Craft_Pages {
					__typename
					slug
					title
					pageTheme
					headerOverlay
					hideTitle
					matrixBuilder {
						...MatrixBuilderQuery
					}
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
		}
	}
`
