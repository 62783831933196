import React from 'react'
import styled from 'styled-components'
import Button from '../Atoms/Button'
import {
	InputWrapper,
	StyledInput,
	StyledTextArea,
	StyledLabel,
	TwoThirdsCenter,
	FlexWrapper
} from '../shared'

const FilledInput = props => (
	<InputWrapper>
		<StyledInput
			type={props.text}
			placeholder={props.placeholder}
			name={props.name}
			aria-label={props.arialabel}
		/>
	</InputWrapper>
)

const untwclassnames =
	'border border-grey-10 focus:border-grey-dark-30 transition-duration-200'

export default function Formstart(props) {
	return (
		<TwoThirdsCenter>
			<form
				action="https://formspree.io/jhecht+lists@smartassdesign.com"
				method="POST"
				className="w-full"
			>
				<FlexWrapper>
					<InputWrapper>
						<StyledLabel>
							Tell us a little about your business and project
						</StyledLabel>
						<StyledTextArea
							className={untwclassnames}
							placeholder="Start typing here..."
							name="Tell us about your project."
							rows="6"
						/>
					</InputWrapper>
					<InputWrapper>
						<StyledLabel>What led you to Pensa?</StyledLabel>
						<StyledTextArea
							className={untwclassnames}
							placeholder="Start typing here..."
							name="What led you to Pensa?"
							rows="6"
						/>
					</InputWrapper>
					<InputWrapper>
						<StyledLabel as="h4">Who can we contact to follow up?</StyledLabel>
						<StyledInput
							type="text"
							placeholder="Jane"
							name="First Name"
							aria-label="First name"
						/>
					</InputWrapper>
					<FilledInput
						type="text"
						placeholder="Doe"
						name="Last Name"
						aria-label="Last name"
					/>
					<FilledInput
						type="email"
						name="_replyto"
						placeholder="email@emailaddress.com"
						aria-label="Email address"
					/>
					<FilledInput
						type="text"
						name="Company Name"
						placeholder="Company Name"
						aria-label="Company name"
					/>

					<InputWrapper className="text-center">
						<Button as="input" type="submit" value="Submit" />
					</InputWrapper>
				</FlexWrapper>
			</form>
		</TwoThirdsCenter>
	)
}
// <span className="sr-only hidden">Pancakes Opt-in</span>
