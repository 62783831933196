import React from 'react'
import styled from 'styled-components'
import Button from '../Atoms/Button'
import {
	InputWrapper,
	StyledInput,
	StyledTextArea,
	StyledLabel,
	TwoThirdsCenter,
	FlexWrapper
} from '../shared'

const FilledInput = props => (
	<InputWrapper>
		<StyledInput
			type={props.text}
			placeholder={props.placeholder}
			name={props.name}
			aria-label={props.arialabel}
		/>
	</InputWrapper>
)

export default function Formmain(props) {
	return (
		<TwoThirdsCenter>
			<form
				action="https://formspree.io/jhecht+lists@smartassdesign.com"
				method="POST"
				className="w-full"
			>
				<FlexWrapper>
					<FilledInput
						type="text"
						placeholder="Jane"
						name="First Name"
						arialabel="First name"
					/>
					<FilledInput
						type="text"
						placeholder="Doe"
						name="Last Name"
						arialabel="Last name"
					/>
					<FilledInput
						type="email"
						name="_replyto"
						placeholder="email@emailaddress.com"
						arialabel="Email address"
					/>
					<FilledInput
						type="text"
						name="Company Name"
						placeholder="Company Name"
						aria-label="Company name"
					/>
					<FilledInput
						type="url"
						placeholder="Company Website"
						name="Company Website"
						aria-label="Company website"
					/>

					<InputWrapper>
						<StyledLabel>
							In just a few sentences please describe the hardware project you
							are working on and what stage you are in e.g first prototype,
							manufacturing, distributing products etc.
						</StyledLabel>
						<StyledTextArea
							placeholder="Start typing here..."
							name="Hardware Project"
							rows="6"
						/>
					</InputWrapper>
					<InputWrapper>
						<StyledLabel>
							Briefly explain what issues you are facing at the moment and the
							general topics you would like to discuss during the breakfast.
						</StyledLabel>
						<StyledTextArea
							placeholder="Start typing here..."
							name="Current Issues"
							rows="6"
						/>
					</InputWrapper>
					<InputWrapper>
						<StyledLabel>
							How did you hear about Pancakes* with Pensa?
						</StyledLabel>
						<StyledTextArea
							placeholder="Start typing here..."
							name="How Did You Hear?"
							rows="6"
						/>
					</InputWrapper>
					<InputWrapper>
						<label className="block text-grey font-bold">
							<input
								className="mr-2 leading-tight"
								type="checkbox"
								name="Promotional Opt-in"
							/>
							<span className="text-sm">
								By attending our Pancakes* at Pensa event, I agree that I may be
								photographed and the photo may be used for promotional
								materials.
							</span>
						</label>

						<label className="block text-grey font-bold">
							<input
								className="mr-2 leading-tight"
								type="checkbox"
								name="Mailing List"
							/>
							<span className="text-sm">
								Optional: I would like to subscribe to the mailing list to
								receive updates regarding upcoming Pancakes* events &
								information.
							</span>
						</label>
					</InputWrapper>
					<InputWrapper className="text-center">
						<Button as="input" type="submit" value="Send" />
					</InputWrapper>
				</FlexWrapper>
			</form>
		</TwoThirdsCenter>
	)
}
// <span className="sr-only hidden">Pancakes Opt-in</span>
